import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const KotItemCancel = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;

    const [items, setItems] = useState([]);
    const print_hidekotno = appState?.[0]?.print_hidekotno || 0; 
    const fs_cname =appState?.[0]?.fs_cname || 36; 
    const fs_kotno = appState?.[0]?.fs_kotno || 0;
    const fs_kottype =appState?.[0]?.fs_kottype || 0; 

    const [DeptData, setDeptData] = useState([]);
    const [DeptDataselected, setDeptDataselected] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/deptmas`) // Replace with your API endpoint
            .then(response => setDeptData(response.data))
            .catch(error => console.error('Error fetching data:', error));
    }, [usershopid]);

    const onChangedept = (e) => {
        setDeptDataselected(e.target.value);
    }

    const handlefindItem = () => {
        console.log(DeptDataselected);
        if (DeptDataselected == 0 ||DeptDataselected == "") {
            toast.error("Please Enter Kot N...");
            document.getElementById("DeptDataselected").focus();
            return false;
        }
        setIsLoading(true);
            console.log(`${hostlink}/findtableitemTRFKOTNO/${usershopid}/${DeptDataselected}`)
            axios.get(`${hostlink}/findtableitemTRFKOTNO/${usershopid}/${DeptDataselected}`)
            .then(response => {
                const fetchedItems = response.data;
                setItems(fetchedItems);
                setIsLoading(false);
            })
            .catch((res)=>{
                toast.error("Please Enter Kot No...");
                setIsLoading(false);
            });
    }

    const handleCheckboxChange = (itemId) => {
        setSelectedItems(prevSelectedItems =>
            prevSelectedItems.includes(itemId)
                ? prevSelectedItems.filter(id => id !== itemId)
                : [...prevSelectedItems, itemId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(items.map(item => item[0])); // Assuming each item has a unique 'id'
        }
        setSelectAll(!selectAll);
    };

    const printdata = async (e) => {
        console.log(e)
        try {
            const response = e;

            
                const printContent = generatePrintContent(response, print_hidekotno);

                const data = `1,${hostlink}/KotviewOne/${usershopid}/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }

                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);

                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();

                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        @page {
                            size: 80mm 297mm; /* Set size to 80mm width */
                            margin: 0; /* Remove margins */
                        }
                        html, body {
                            margin: 0; /* Remove margins */
                            padding: 0; /* Remove padding */
                            width: 80mm; /* Set width to 80mm */
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        body {
                            width: 100%;
                        }
                    }
                `;
                doc.head.appendChild(style);

                // Ensure the content is fully loaded before printing
                setTimeout(() => {
                    console.log("Print window content:", doc.body.innerHTML);

                    // Calculate content height
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);

                    // Print
                    printWindow.contentWindow.print();

                    // Send cut command if content height exceeds 210mm
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }

                }, 1000);
            
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };


    const generatePrintContent = (data, print_hidekotno) => {
        const tableInfo = data.length > 0 && data[0].tablename !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Table : ${data[0].tablename}</p1></span>` : '';

        const snHeader = print_hidekotno === 0 ? '<td style="width: 10%;border-bottom: 1px solid black;">SN</td>' : '';
        const snRows = print_hidekotno === 0 ? data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td><td style="width: 80%">${x.itname}</td><td style="width: 10%; text-align: end;">${x.qty}</td></tr>`).join('') :
            data.map((x) => `<tr style="width: 100%"><td style="width: 80%">${x.itname} <br />${x.itcomment !== '' ? `Comment : ${x.itcomment}` : ''}</td><td style="width: 10%; text-align: end;">${x.qty.toFixed(2)}</td></tr>`).join('');

        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}
            div{width: 3in;}hr{width: 3in;}</style>
            <div style="font-family: 'Bahnschrift Condensed'; width: 3in;">
             <h1>${data[0].status == 2 ? `Cancelled` : ''}</h1> </br> 
                <p1 style="display: block; margin: 0 auto;text-align: center; font-Size:${fs_kottype}px;">${data.length > 0 ? data[0].KottypeName : ''}</p1>
                <h1 style="text-align: center;font-Size:${fs_cname}px;">${appState?.[0]?.cname || '-'}</h1>
                <hr style="borderTop: 1px solid black; width: 3in;" />
            <h1 style="text-align: center;">MODIFIED</h1>
                <span style="display:flex;">
                    <p1 style="text-align: start; width:50%; font-Size:${fs_kotno}px;">Kot No : ${data.length > 0 ? data[0].shopvno : ''}</p1>
                    ${tableInfo}
                </span>
                <span style="display:flex; width: 3in;">
                    <p1 style="text-align: start; width:50%;">Date : ${data[0].kotdate ? new Date(data[0].kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1>
                    <p1 style="text-align: end; width:50%;"> Time : ${data[0].kottime ? new Date(`2022-01-01T${data[0].kottime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1>
                </span>
                <span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Waiter : ${data[0].kotdate ? data[0].wname : "NA"}</p1><p1 style="text-align: end; width:50%;"> NOP : ${data[0].kotdate ? data[0].nop : "NA"}</p1></span>
                <table style="border-Top: 1px solid black; width: 3in;">
                    <tr style="border-bottom: 1px solid black;">
                        ${snHeader}
                        <td style="width: 80%;border-bottom: 1px solid black;">Item Name</td>
                        <td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td>
                    </tr>
                    ${snRows}
                </table>
                <hr style="borderTop: 1px solid black; width: 3in;" />
                <span style="display:flex;">
                    <p1 style="text-align: end; width:100%;">Total Qty : ${Number(data.reduce((total, resp) => total + parseFloat(resp.qty), 0))}</p1>
                </span>
                <hr style="borderTop: 1px solid black; width: 3in;" />      
            </div>`;
    };
    const handleSave = async () => {
        setIsLoading(true);
        const selectedData = items.filter(item => selectedItems.includes(item.id));
        console.log(selectedData);
        // 
        // return false;        
        try {
            await axios.put(`${hostlink}/DineinKOTItemTRF`, items);
            toast.success("Save Successful");
            setIsLoading(false);
            await printdata(selectedData)
            setTimeout(() => {
                window.location.assign("/KotItemCancel");
                setIsLoading(false);
            }, 1000);
        } catch {
            toast.error("Save failed.");
            setIsLoading(false);
        }
    };

    const handleAddItemRemarks = (e,itemremarks) => {
        const existingItemGrid = items.find(item => item.rawcode === e);
        if (existingItemGrid) {
            const updatedItDetails = items.map(item => {
                if (item.rawcode === e) {
                    return { ...item, itemremarks: itemremarks.target.value,itcomment:itemremarks.target.value}; 
                }
                return item; 
            });
            setItems(updatedItDetails);
        } else {
            toast.error("Item not found in the item detail grid.");
        }
    };

    const handleLessQty = (e) => {
        const existingItemGrid = items.find(item => item.rawcode === e);
        if (existingItemGrid) {
            const updatedItDetails = items.map(item => {
                if (item.rawcode === e) {
                    if (item.qty === 0) {
                        toast.error("Item Qty is 0, you can't decrease it further.");
                        return item; 
                    }
                    return { ...item, qty: Number(item.qty) - 1 }; 
                }
                return item; 
            });
            setItems(updatedItDetails);
        } else {
            toast.error("Item not found in the item detail grid.");
        }
    };
    

    const handleAddQty = (e) => {
        const existingItemGrid = items.find(item => item.rawcode === e);
        if (existingItemGrid) {
            const updatedItDetails = items.map(item => {
                if (item.rawcode === e) {
                    return { ...item, qty: Number(Number(item.qty) + 1) };
                }
                return item;
            });
            setItems(updatedItDetails);
            toast.success("Quantity Updated..");
        }
    };
    const handleCancel = () => {
        setSelectedItems([]);
        setSelectAll(false);
    };

    return (
        <div className="container mt-4">
            <h5 style={{color:"blue"}}> <i class="fa-solid fa-ban" style={{ width: "20px" }}></i> <u> Cancel Kot Item </u></h5>
            <div className='row'>

                <div className="col-md-3 text-end">
                        
                </div>

                <div className="col-md-2 text-end align-content-center">
                    <h6>Enter Kot No :</h6>
                </div>
                
                <div className="col-md-2">
                    <input name='DeptDataselected' value={DeptDataselected} id='DeptDataselected' onChange={(e)=>{onChangedept(e)}} className='form-control' type="text" />
                </div>
                <div className="col-md-2 text-start">
                    <button onClick={handlefindItem} className='btn btn-outline-dark'>Load Item</button>
                </div>
            </div>
<br />
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Qty</th>
                        <th>Table</th>
                        <th>Waiter</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => (
                        <tr key={item.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                                {item.itname}
                            </td>
                            <td><div className='col-md-4 align-self-center text-center'><button type="submit" onClick={(e) => { handleLessQty(item.rawcode) }} className="btn btn-sm "><i class="fa-solid fa-minus" style={{ color: "red" }}></i></button>{item.qty}<button type="submit" onClick={(e) => { handleAddQty(item.rawcode) }} className="btn btn-sm "><i class="fa-solid fa-plus" style={{ color: "red" }}></i></button></div></td>
                            <td>{item.tablename}</td>
                            <td>{item.wname}</td>
                            <td style={{width:"250px"}}><input type="text" onChange={(e)=>{handleAddItemRemarks(item.rawcode,e)}} defaultValue={item.itemremarks} class="form-control" /> </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-flex justify-content-end">
                <button className="btn btn-primary me-2" onClick={handleSave}>
                    Save
                </button>
                <Link to={"/"} className="btn btn-secondary" onClick={handleCancel}>
                    Cancel
                </Link>
            </div>

            <ToastContainer />
            {isLoading && <Loading />}
        </div>
    );
};

export default KotItemCancel;

