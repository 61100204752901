import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import hostlink from '../Hostlink/hostlink'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
const Logbook = () => {
  
  const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
  const usershopid = appState?.[0]?.id || 0;
  const [data, setData] = useState()
  const [refreshData, setRefreshData] = useState(false)
  const api = "/logbookview";
  const today = new Date();
  const [frmdata, setfrmdata] = useState({
    fromdate: today.toISOString().split('T')[0],
    todate: today.toISOString().split('T')[0],

  });
  const { fromdate, todate } = frmdata;

  const refreshform = (e) => {
    // axios.get(`${hostlink}/${usershopid}${api}/${fromdate}/${todate}`)
    //   .then((Response) => {
    //     console.log("Data Fatched")
    //     setData(Response.data)
    //   })
    //   .catch((Response) => {
    //     console.log("Data Not Fatched")
    //   })
  }
  const onChangevalue = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
    setfrmdata({ ...frmdata, [name]: sanitizedValue });

  };

  const columns = [
    { name: 'Sr No', selector: row => row.id, cell: (row, index) => <div>{index + 1}</div>, "width": "5%" },
    { name: 'Date', sortable: true, selector: row => row.logdate, "width": "8%" },
    { name: 'Time', sortable: true, selector: row => row.logtime, "width": "7%" },
    { name: 'Form', sortable: true, selector: row => row.onform, "width": "8%" },
    { name: 'Action', sortable: true, selector: row => row.operation, "width": "8%" },
    { name: 'New Description', sortable: true, selector: row => row.newdesc, wrap: true },
    { name: 'Old Description', sortable: true, selector: row => row.olddesc, wrap: true },
    {
      name: 'Actions', "width": "0%", cell: (row) => (<div>
      </div>), allowOverflow: true, button: true,
    },
  ];

  useEffect(() => {
    axios.get(`${hostlink}/${usershopid}${api}/${fromdate}/${todate}`)
      .then((Response) => {
        console.log("Data Fatched")
        setData(Response.data)
      })
      .catch((Response) => {
        console.log("Data Not Fatched")
      })
  }, [usershopid])
  return (

    <div className="container p-1" >
      <div className='row p-1'>
        <div className='col-md-6 p-2'>
          <h5 className='font-monospace'><i class="fa-solid fa-chart-simple"></i> Logbook</h5>
        </div>

        <div className='col-md-1 align-self-center text-center'>
          <label htmlFor="fromdate" className="form-label">From :</label>
        </div>
        <div className='col-md-2'>
          <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} onBlur={(e) => { refreshform(e) }} value={fromdate} className="form-control" />
        </div>
        <div className='col-md-1 align-self-center text-center'>
          <label htmlFor="todate" className="form-label">To :</label>
        </div>
        <div className='col-md-2'>
          <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} onBlur={(e) => { refreshform(e) }} value={todate} className="form-control" />
        </div>

      </div>

      <div className='col-12' style={{ maxHeight: "1400px", overflow: "auto" }}>

        <div style={{ height: "80vh" }} className='col-12 '>
          <table class="table table-striped">
            <tbody>
              <DataTable
                columns={columns}
                data={data}
                pagination
                fixedHeader
                fixedHeaderScrollHeight='530px'
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>


  )
}

export default Logbook