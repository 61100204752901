import React, { useEffect, useRef, useState ,useContext} from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_Stock_Report = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [itgdata, setitgdata] = useState([])
    
    const [data, setData] = useState([]);
    const today = new Date();
    
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: usershopdeptcode,
        status: 0,
        itgcode: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode, itgcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async (e) => {
        setIsLoading(true)
        try {
            console.log(`${hostlink}/StockReportITG/${usershopid}/${queryCondition}/${deptcode}/${fromdate}/${todate}/${usershopdeptcode == deptcode ? 0 : 1}/${itgcode}`)
            const billResponse = await axios.get(`${hostlink}/StockReportITG/${usershopid}/${queryCondition}/${deptcode}/${fromdate}/${todate}/${usershopdeptcode == deptcode ? 0 : 1}/${itgcode}`);
            const billData = billResponse.data;
            setData(billResponse.data)
            setIsLoading(false)
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(() => {
        axios.get(`${hostlink}/${queryCondition}/deptmas`)
            .then((Response) => { setdeptdata(Response.data) })
        axios.get(`${hostlink}/${queryCondition}/ItemGroup`)
            .then((Response) => { setitgdata(Response.data) })
            setfrmdata({
                ...frmdata,
	deptcode: usershopdeptcode,
            });
    }, [usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Stock Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-bars-staggered"></i> <span id='pagemode'> Stock Report</span></h5>
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="fromdate" className="form-label">From Date </label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-6 col-md-1 align-self-center text-end'>
                        <label htmlFor="todate" className="form-label">To Date </label>
                    </div>
                    <div className='col-6 col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-6 col-md-1 align-self-end text-end'>
                        <label htmlFor="roomtype" className="form-label">Department </label>
                    </div>
                    <div className="col-6 col-md-2 text-start-0 align-self-center">
                        <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                            {deptdata.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.deptname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='col-12 col-md-1 align-self-end text-end'>

                    </div>

                    <div className='col-6 col-md-3 align-self-end text-end'>
                        <label htmlFor="roomtype" className="form-label">Item Group </label>
                    </div>
                    <div className="col-6 col-md-2 text-start-0 align-self-center">
                        <select id="itgcode" name='itgcode' value={itgcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                            <option selected key={0} value={0}>All</option>
                            {itgdata.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.groupname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='col-12 col-md-6 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Stock Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Sr No</th>
                            <th className='text-start' scope="col">Item Name</th>
                            <th className='text-end' scope="col">Opening</th>
                            <th className='text-end' scope="col">Stock In</th>
                            <th className='text-end' scope="col">Stock Out</th>
                            <th className='text-end' scope="col">Closing</th>
                            <th className='text-end' scope="col">Unit</th>
                            <th className='text-end' scope="col">Sale Rate</th>
                            <th className='text-end' scope="col">Amount</th>
                            <th className='text-end' scope="col">Purc Rate</th>
                            <th className='text-end' scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x}>
                                <td>{x + 1}</td>
                                <td className='text-start'>{res.itname}</td>
                                <td className='text-end'>{Number(res.Opening).toFixed(3)}</td>
                                <td className='text-end'>{res.StockIn}</td>
                                <td className='text-end'>{res.StockOut}</td>
                                <td className='text-end'>{Number(res.Closing).toFixed(3)}</td>
                                <td className='text-end'>{res.unitname}</td>
                                <td className='text-end'>{res.rate}</td>
                                <td className='text-end'>{Number(res.Closing * res.rate).toFixed(2)}</td>
                                <td className='text-end'>{res.lastPurchaseRate}</td>
                                <td className='text-end'>{Number(res.Closing * res.lastPurchaseRate).toFixed(2)}</td>
                            </tr>
                        ))}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><h6>Total QTY:</h6></td>
                            <td className='text-end'><h6>{data.reduce((total, res) => total + parseFloat(Number(res.Closing)), 0)}</h6></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><h6>{data.reduce((total, res) => total + parseFloat(Number(res.Closing * res.rate).toFixed(2)), 0)}</h6></td>
                            <td></td>
                            <td className='text-end'><h6>{data.reduce((total, res) => total + parseFloat(Number(res.Closing * res.lastPurchaseRate).toFixed(2)), 0)}</h6></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Stock Report</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Sr No</Text>
                            <Text style={styles.headerCell}>Item Name</Text>
                            <Text style={styles.headerCell}>Opening</Text>
                            <Text style={styles.headerCell}>Stock In</Text>
                            <Text style={styles.headerCell}>Stock Out</Text>
                            <Text style={styles.headerCell}>Closing</Text>
                            <Text style={styles.headerCell}>Unit</Text>
                            <Text style={styles.headerCell}>Sale Rate</Text>
                            <Text style={styles.headerCell}>Amount</Text>
                            <Text style={styles.headerCell}>Purc Rate</Text>
                            <Text style={styles.headerCell}>Amount</Text>
                        </View>
                        {data.map((res, x) => (
                            <View key={x} style={styles.tableRow}>
                                <Text style={styles.cell}>{x + 1}</Text>
                                <Text style={styles.cell}>{res.itname}</Text>
                                <Text style={styles.cell}>{res.Opening}</Text>
                                <Text style={styles.cell}>{res.StockIn}</Text>
                                <Text style={styles.cell}>{res.StockOut}</Text>
                                <Text style={styles.cell}>{res.Closing}</Text>
                                <Text style={styles.cell}>{res.unitname}</Text>
                                <Text style={styles.cell}>{res.rate}</Text>
                                <Text style={styles.cell}>{Number(res.Closing * res.rate).toFixed(2)}</Text>
                                <Text style={styles.cell}>{res.lastPurchaseRate}</Text>
                                <Text style={styles.cell}>{Number(res.Closing * res.lastPurchaseRate).toFixed(2)}</Text>
                            </View>
                        ))}
                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total :</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.Closing)), 0)}</Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.Closing * res.rate).toFixed(2)), 0)}</Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.Closing * res.lastPurchaseRate).toFixed(2)), 0)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});



export default Rpt_Stock_Report