import React, { useEffect, useRef, useState,useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const Rpt_PurchaseReport = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopidBatchNo = appState?.[0]?.showbatchinpurchase || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [listVisible, setListVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [filteredData, setFilteredData] = useState([]);
    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemdetail, setitemdetail] = useState(0);
    const [query, setQuery] = useState('');
    const inputRef = useRef(null);
    
    const queryCondition = usershopidref !== 0 ? usershopidref : usershopid;
    const fetchData = async () => {
        if (usershopidref == 0) {
            const response = await fetch(`${hostlink}/${usershopid}/item`);
            const jsonData = await response.json();
            setData(jsonData);
        } else {
            console.log(`${hostlink}/${queryCondition}/${deptcode}/storeitem`)
            // const response = await fetch(`${hostlink}/${queryCondition}/item`);
            const response = await fetch(`${hostlink}/${queryCondition}/${deptcode}/storeitem`);
            const jsonData = await response.json();
            setData(jsonData);
        }
    };
    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
            event.target.setSelectionRange(0, event.target.value.length); // Select the input value
        }
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {

            } else if (event.keyCode === 9) {

            }
        }
    };
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };
    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].itname);
            const queryCondition = filteredData[selectedIndex].ispackage == 0
                ? filteredData[selectedIndex].restrate
                : 0;

            setitemdetail(filteredData[selectedIndex].id);

        }
    };
    const handleItemClick = (index) => {
        const queryCondition = filteredData[index].ispackage == 0
            ? filteredData[index].restrate
            : 0;

        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].itname);
        setitemdetail(filteredData[index].id);

    };
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    useEffect(() => {
        fetchData();
    }, [usershopid]);

    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const [DataList, setDataList] = useState([]);

    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: 0,
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async (e) => {
        const queryCondition = document.getElementById("inputname1111").value === "" ? 0 : itemdetail;
        try {
            console.log(`${hostlink}/purcmasReport/${usershopid}/${fromdate}/${todate}/${deptcode}/${queryCondition}`)
            const billResponse = await axios.get(`${hostlink}/purcmasReport/${usershopid}/${fromdate}/${todate}/${deptcode}/${queryCondition}`);
            const billData = billResponse.data;
            setDataList(billResponse.data)
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/accmas`)
            .then((Response) => { setdeptdata(Response.data) })
    }, [usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Stock Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-cart-shopping"></i> <span id='pagemode'> Purchase Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Party:</label>
                    </div>
                    <div className="col-12 col-md-2 align-self-center text-center">
                        <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                            <option selected key={0} value={0}>All</option>
                            {deptdata.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.accname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='col-md-4 align-self-center text-end'>
                        {/* <label htmlFor="roomtype" className="form-label">Item :</label> */}
                    </div>
                    <div className="col-md-5 mt-2">

                        <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} /> <br />
                        {listVisible && filteredData.length > 0 && (
                            <ul style={{}}>
                                {filteredData.map((item, index) => (
                                    <li
                                        key={item.id}
                                        onClick={() => handleSelectItem(item, index)}
                                        onMouseDown={() => handleItemClick(index)}

                                        className={index === selectedIndex ? 'selected' : ''}
                                    >
                                        {item.itname} - ₹{item.restrate}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                    <div className='col-md-3 align-self-center text-end '>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={DataList} fromdate={fromdate} todate={todate} batch={appState?.[0]?.showbatchinpurchase || 0} />} fileName="Purchase Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >


                            <th className='text-start' scope="col">Department</th>
                            <th className='text-start' scope="col">Bill No</th>
                            <th className='text-start' scope="col">Bill Date</th>
                            <th className='text-start' scope="col">Party Name</th>
                            <th className='text-start' scope="col">Item Name</th>

                            {usershopidBatchNo === 1 && (
                                <>
                                    <th className='text-end' scope="col">Batchno</th>
                                    <th className='text-end' scope="col">Expiry</th>
                                </>
                            )}
                            <th className='text-end' scope="col">Qty</th>
                            <th className='text-end' scope="col">Unit</th>
                            <th className='text-end' scope="col">Rate</th>
                            <th className='text-end' scope="col">GST%</th>
                            <th className='text-end' scope="col">Amount</th>
                            <th className='text-end' scope="col">Bill Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {DataList.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res[0] === data[x - 1][0];


                            return (
                                <tr key={x}>

                                    <td className='text-start'>{isDuplicate ? '' : res[5]}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res[2]}</td>
                                    {/* Conditionally render blank data if it's the same as the previous row */}
                                    <td className='text-start'>{isDuplicate ? '' : res[1] ? new Date(res[1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res[4]}</td>
                                    <td className='text-start'>{res[6]}</td>
                                    {usershopidBatchNo === 1 && (
                                        <>
                                            <td className='text-end'>{res[15]}</td>
                                            <td className='text-end'>{res[14] ? new Date(res[14]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : ""}</td>
                                        </>
                                    )}



                                    <td className='text-end'>{res[7]}</td>
                                    <td className='text-end'>{res[16]}</td>
                                    <td className='text-end'>{res[8]}</td>
                                    <td className='text-end'>{res[9]}</td>
                                    <td className='text-end'>{res[13]}</td>
                                    <td className='text-end'>{isDuplicate ? '' : res[3]}</td>
                                </tr>
                            );
                        })}

                        <tr class="table-info">
                            <td></td>

                            <td></td>
                            {usershopidBatchNo === 1 && (
                                <>
                                    <td></td>
                                    <td></td>
                                </>
                            )}
                            <td></td>
                            <td></td>
                            <td className='text-end'><h6>Total:</h6></td>
                            <td className='text-end'><h6>{DataList.reduce((total, res) => total + parseFloat(Number(res[7])), 0)}</h6></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'>
                                <h6>
                                    {DataList.reduce((total, res, x) => {
                                        // Check if current element is a duplicate based on the previous one
                                        const isDuplicate = x > 0 && res[0] === DataList[x - 1][0];
                                        return isDuplicate ? total : total + parseFloat(res[3] || 0);
                                    }, 0)}
                                </h6>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const PDFDocument = ({ data, fromdate, todate, batch }) => {
    
    const usershopidBatchNo = batch;
    
    // Calculate total bill amount
    // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Purchase Report</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>

                            <Text style={styles.headerCell}>Department</Text>
                            <Text style={styles.headerCell}>Bl No</Text>
                            <Text style={styles.headerCell}>Bl Date</Text>
                            <Text style={styles.headerCell}>Party Name</Text>
                            <Text style={styles.headerCell}>Item Name</Text>
                            {usershopidBatchNo === 1 && (
                                <>
                                    <Text style={styles.headerCell}>Batchno</Text>
                                    <Text style={styles.headerCell}>Expiry</Text>
                                </>
                            )}

                            <Text style={styles.headerCell}>Qty</Text>
                            <Text style={styles.headerCell}>Unit</Text>
                            <Text style={styles.headerCell}>Rate</Text>
                            <Text style={styles.headerCell}>Gst%</Text>
                            <Text style={styles.headerCell}>Amount</Text>
                            <Text style={styles.headerCell}>Bill Amount</Text>
                        </View>

                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res[0] === data[x - 1][0];
                            return (
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>{isDuplicate ? '' : res[5]}</Text>
                                    <Text style={styles.cell}>{isDuplicate ? '' : res[2]}</Text>
                                    <Text style={styles.cell}>{isDuplicate ? '' : res[1] ? new Date(res[1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
                                    <Text style={styles.cell}>{isDuplicate ? '' : res[4]}</Text>
                                    <Text style={styles.cell}>{res[6]}</Text>
                                    {usershopidBatchNo === 1 && (
                                        <>
                                            <Text style={styles.cell}>{res[15]}</Text>
                                            <Text style={styles.cell}>{res[14] ? new Date(res[14]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : ""}</Text>
                                        </>
                                    )}

                                    <Text style={styles.cell}>{res[7]}</Text>
                                    <Text style={styles.cell}>{res[16]}</Text>
                                    <Text style={styles.cell}>{res[8]}</Text>
                                    <Text style={styles.cell}>{res[9]}</Text>
                                    <Text style={styles.cell}>{res[13]}</Text>
                                    <Text style={styles.cell}>{isDuplicate ? '' : res[3]}</Text>
                                </View>
                            );
                        })}

                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            {usershopidBatchNo === 1 && (
                                <>
                                    <Text style={styles.cell}></Text>
                                    <Text style={styles.cell}></Text>
                                </>
                            )}
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total :</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res[7])), 0)}</Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>
                                {data.reduce((total, res, x) => {
                                    // Check if current element is a duplicate based on the previous one
                                    const isDuplicate = x > 0 && res[0] === data[x - 1][0];
                                    return isDuplicate ? total : total + parseFloat(res[3] || 0);
                                }, 0)}

                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});



export default Rpt_PurchaseReport